<template>
    <div class="home">
        <div class="home__image d-flex justify-content-between flex-column">
            <h1 class="home__title my-5 d-flex flex-column align-items-start">
                <span class="d-flex flex-column">
                    <span>Med'5P</span>
                    <span class="home__title__underline mb-4"></span>
                </span>
                <span class="my-3 text-center">
                    L'ÉVÉNEMENT PHARE DE NOVEKA DÉDIÉ À L'INNOVATION EN SANTÉ, EDITION
                    <span class="text-nowrap">2024 !</span>
                </span>
            </h1>
            <div class="cab mb-3 me-4">
                <MyCountdown class="d-none d-md-block" :date="countdown_end" />
            </div>
        </div>

        <div class="home__description container d-flex-center flex-column py-5">
            <MyCountdown class="d-sm-block d-md-none phonemode align-self-stretch" :date="countdown_end"></MyCountdown>

            <div class="separation"></div>

            <div class="d-flex-center flex-column my-4 text-center">
                <h1 class="my-4">5ÈME ÉDITION</h1>
                <h2 class="my-2 text-secondary">La créativité au service <span class="text-nowrap">de la santé</span></h2>

                <span class="my-3">Jeudi 21 novembre 2024 à 13h30</span>
                <span class="text-secondary">Centre Hygée</span>
                <span>1 Chem. de la Marandière</span>
                <span>42270 Saint-Priest-en-Jarez</span>
            </div>

            <router-link
                :to="{ name: 'inscription' }"
                class="btn btn-lg btn-bicolor fw-bold my-4 d-flex-center"
                style="width: 200px; height: 65px; text-transform: uppercase"
            >
                S'inscrire
            </router-link>

            <div class="separation"></div>

            <div class="text-container">
                <h2 class="text-secondary mb-2">LE CONCEPT</h2>
                <div class="text-justify border-start border-secondary border-5 ps-4 mt-2--5">
                    <p>
                        Après les thématiques de la <span class="text-secondary">Prévention</span> en 2020, de la
                        <span class="text-secondary">Prédiction en santé</span> en 2021, puis de la Santé
                        Environnementale en 2022 et 2023, Novéka poursuit sa démarche de
                        <span class="text-secondary">
                            promotion de l'innovation en santé focalisée sur les enjeux de prévention à l'échelle du
                            territoire.
                        </span>
                        <br />
                        En collaboration avec <span class="text-secondary">La Rotonde</span> (CCSTI de Mines Saint
                        Etienne), nous vous proposons cette année encore d'être acteur de l'événement en prenant part
                        aux <span class="text-secondary">ateliers participatifs</span>, cœur du Med'5P.
                        <br />
                        <i>
                            Transversalité des expertises et des angles de vue, approche centrée utilisateurs / usagers
                            / clients / patients / praticiens, interdisciplinarité, apport des sciences humaines et
                            sociales et du design, potentiel d'usage des outils numériques, partage d'expérience sont
                            les ingrédients clés de l'événement.
                        </i>
                    </p>

                    <p>
                        Des expertises techniques, des points de vue différents, des retours de terrain sont mobilisés
                        sur chaque atelier pour enrichir les échanges et apporter des regards croisés ou décalés et du
                        concret. Une méthodologie guide le déroulé de l'atelier encadré par un animateur.
                    </p>
                    <p class="fw-bold">
                        L'objectif est de faire remonter des <span class="text-secondary">problématiques</span>, des
                        <span class="text-secondary">cas pratiques</span> et des
                        <span class="text-secondary">idées</span> qui puissent alimenter l'émergence de projets
                        collaboratifs visant à concevoir des
                        <span class="text-secondary">solutions innovantes de santé</span> (produits, services,
                        organisations, …), tout en tenant compte de critères tels que la faisabilité, la soutenabilité
                        (au sens du développement durable) et les ressources locales disponibles.
                    </p>
                </div>

                <h2 class="text-primary mb-2 mt-5">LA THEMATIQUE 2024</h2>
                <div class="text-justify border-start border-primary border-5 ps-4 mt-2--5">
                    <p>
                        Lors de cette 5e édition, nous vous proposons de réfléchir collectivement autour de 3 axes :
                        <span class="text-primary"
                            >(1) la santé mentale, (2) la prévention des cancers et (3) la RSE</span
                        >
                        qui constitueront les 3 tables rondes de l'événement, au travers des
                        <span class="text-primary">technologies, savoir-faire et méthodologies</span> portés par les
                        <span class="text-primary">industries créatives</span> !
                    </p>
                    <p>
                        L'environnement culturel et social d'une population, tout comme les ressorts motivationnels
                        propres à chaque individu, impactent la réussite d'un projet. C'est particulièrement le cas en
                        santé et quels que soient les objectifs fixés : sensibilisation, prévention, dépistage,
                        éducation thérapeutique, traitement…
                    </p>

                    <p class="fw-bold">
                        <span class="text-primary">
                            Comment le jeu (vidéo, escape game, jeu de société, jeu de plateau, …), la scénarisation
                            (story stelling en anglais) ou encore la réalité virtuelle peuvent être mis au service de
                            projets pour répondre à des enjeux de médiation, d'engagement/ empowerment, d'appropriation,
                            ou encore d'inflexion comportementale dans la durée ?
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";

export default {
    components: {
        MyCountdown,
    },
    data() {
        return {
            countdown_end: new Date("2024-11-21T14:00:00"),
        };
    },
};
</script>
