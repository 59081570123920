<template>
    <div class="acces">
        <div class="infos-content text-white position-relative">
            <h2 class="title-section">Infos & Accès</h2>
            <div class="container py-5">
                <div class="d-flex justify-content-md-around flex-wrap mt-2">
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600 pb-1">
                            <i class="fa-solid fs-2 fa-location-dot text-secondary me-3"></i>
                            <span>Adresse</span>
                        </h4>
                        <div class="ms-5 mt-3">
                            Centre Hygée
                            <br />
                            1 Chem. de la Marandière
                            <br />
                            42270 Saint-Priest-en-Jarez
                        </div>
                    </div>
                    <div class="m-4">
                        <h4 class="d-flex align-items-center text-600">
                            <i class="fa-solid fs-2 fa-address-book text-secondary me-2"></i>
                            <span>Coordonnées organisateur</span>
                        </h4>

                        <div class="d-flex flex-column ms-5">
                            <div class="mt-3">
                                <i class="fa-solid fa-user text-primary me-2--5"></i>
                                <b>Pour toute question, veuillez nous contacter</b>
                            </div>
                            <div class="my-2--5">
                                <i class="fa-solid fa-phone text-primary me-2--5"></i>
                                <a href="tel:0477911665">04 77 91 16 65</a>
                            </div>
                            <div>
                                <i class="fa-solid fa-at text-primary me-2--5"></i>
                                <a href="mailto:noveka@noveka.org">noveka@noveka.org</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <img src="../assets/img/plan.png" class="w-100" alt="" />

        <div class="gmap mb-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.2106946196827!2d4.357959068743306!3d45.48061215174851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f5ac556188d963%3A0xdcae9958ff67b818!2s1%20Chem.%20de%20la%20Marandi%C3%A8re%2C%2042270%20Saint-Priest-en-Jarez!5e0!3m2!1sfr!2sfr!4v1723657575393!5m2!1sfr!2sfr"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
</template>

<script>
export default {
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>
