<template>
    <div class="programme pt-3 mb-3 mb-md-5--5">
        <h2 class="title-section mb-5">Programme</h2>

        <div class="d-flex-center flex-column container">
            <table class="programme--table w-100">
                <tr class="border-bottom">
                    <td class="time">13:30</td>
                    <td>Café d'accueil des participants</td>
                </tr>
                <tr>
                    <td class="time">14:00-14:40</td>
                    <td class="d-flex flex-wrap flex-column justify-content-between">
                        <div
                            class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-md-between align-items-start"
                        >
                            <span>
                                Conférence plénière d'introduction
                                <!-- <span class="fw-medium"> Décarboner la santé pour soigner durablement</span> -->
                            </span>
                            <!-- <img
                                src="../assets/img/logo-shifters.jpeg"
                                alt=""
                                class="mx-3 my-3 my-md-0"
                                style="max-width: 100px"
                            /> -->
                        </div>
                        <!-- <div class="intervenant-item d-flex flex-wrap flex-sm-nowrap align-items-center">
                            <div class="intervenant-item__photo rounded-circle d-flex-center">
                                <img src="../assets/img/vial.jpg" alt="" class="h-100 w-100" />
                            </div>
                            <div class="d-flex flex-column ms-4">
                                <span class="intervenant-item__name fw-bold text-primary mb-3 fs-5">Aurore VIAL</span>
                                <small class="intervenant-item__job">Conférencière Shifters Santé</small>
                                <small class="intervenant-item__job">Project manager e-santé</small>
                                <small class="intervenant-item__job">Consultante et enseignante en nutrition</small>
                            </div>
                        </div> -->
                    </td>
                </tr>
            </table>
            <!-- <div class="accordion accordion-flush border rounded my-3 py-0--5 w-100" id="accordionThemes">
                <div class="accordion-item">
                    <h2 class="accordion-header" v-bind:id="`headingvial`">
                        <button
                            class="accordion-button collapsed d-flex flex-wrap align-items-baseline"
                            type="button"
                            data-bs-toggle="collapse"
                            v-bind:data-bs-target="`#vial`"
                            aria-expanded="false"
                            v-bind:aria-controls="`collapsevial`"
                        >
                            <span class="text-nowrap me-2">Présentation de la conférence</span>
                        </button>
                    </h2>
                    <div
                        v-bind:id="`vial`"
                        class="accordion-collapse collapse"
                        v-bind:aria-labelledby="`headingvial`"
                        data-bs-parent="#accordionThemes"
                    >
                        <div class="accordion-body text-italic">
                            <i style="white-space: break-spaces">
                                Le dérèglement climatique va profondément perturber notre système de santé et entraîner
                                des changements majeurs. Il doit en même temps gérer les conséquences de la dégradation
                                des écosystèmes et des crises climatiques sur la santé des populations. Les travaux
                                menés par le Shift Project permettent de dresser un état de lieux des émissions du
                                secteur de la santé et de formuler 37 grands principes d'action pour guider la
                                transformation.
                            </i>
                        </div>
                    </div>
                </div>
            </div> -->
            <table class="programme--table w-100">
                <tr class="border-top">
                    <td class="time">15:00-17:00</td>
                    <td>
                        <div>Ateliers participatifs au choix : <span class="text-primary">3 thèmes proposés</span></div>
                    </td>
                </tr>
            </table>
            <div class="accordion accordion-flush border rounded my-3 py-2 w-100" id="accordionThemes">
                <div class="accordion-item" v-for="item in items" v-bind:key="item.title">
                    <h2 class="accordion-header" v-bind:id="`heading${item.id}`">
                        <button
                            class="accordion-button collapsed d-flex flex-wrap align-items-baseline"
                            type="button"
                            data-bs-toggle="collapse"
                            v-bind:data-bs-target="`#${item.id}`"
                            aria-expanded="false"
                            v-bind:aria-controls="`collapse${item.id}`"
                        >
                            <span class="text-nowrap me-2">{{ item.title }} </span>
                            <span class="text-secondary fw-semibold">{{ item.subtitle }}</span>
                        </button>
                    </h2>
                    <div
                        v-bind:id="`${item.id}`"
                        class="accordion-collapse collapse"
                        v-bind:aria-labelledby="`heading${item.id}`"
                        data-bs-parent="#accordionThemes"
                    >
                        <div class="accordion-body text-italic text-primary">
                            <i style="white-space: break-spaces">{{ item.description }}</i>
                        </div>
                    </div>
                </div>
            </div>
            <table class="programme--table w-100">
                <tr class="border-bottom border-top">
                    <td class="time">17:00-18:45</td>
                    <td>Restitution des tables rondes et cocktail de networking</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            items: [
                {
                    id: "theme1",
                    title: "#1 : [Prévention des cancers]",
                    // subtitle: "Lutter contre la sédentarité dans la sphère professionnelle",
                    description: "Plus d'info à venir...",
                    isVisible: false,
                },
                {
                    id: "theme2",
                    title: "#2 : [Santé mentale]",
                    // subtitle:
                    //     "Détecter précocement les troubles mentaux chez les populations jeunes (adolescents, étudiants et jeunes adultes)",
                    description: "Plus d'info à venir...",
                    isVisible: false,
                },
                {
                    id: "theme3",
                    title: "#3 : [RSE]",
                    // subtitle:
                    //     "Réduire l'impact nocif des dispositifs médicaux sur l'environnement : Vers un cycle vertueux Ecoconception/Recyclage/réemploi",
                    description: "Plus d'info à venir...",
                    isVisible: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
.intervenant-item {
    &__photo {
        overflow: hidden;
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;

        img {
            object-fit: cover;
            object-position: center;
        }
    }
    &__name {
        margin-top: 1rem;
    }
    &__job {
    }
}
</style>
