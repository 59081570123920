<template>
    <div class="container-fluid inscription mx-auto d-flex-center flex-column my-4">
        <div v-if="isLoading" class="loader d-flex-center flex-column">
            <i class="fa-solid fa-stethoscope fa-bounce text-primary-dark fa-5x"></i>
            <strong class="text-white mt-3">Envoi du formulaire en cours...</strong>
        </div>

        <div
            v-if="hasError"
            class="w-100 alert alert-danger text-white d-flex align-items-center rounded-1"
            role="alert"
        >
            <i class="fa-solid fa-times me-3"></i>
            <div>
                Une erreur est survenue lors de la validation de votre inscription, veuillez réessayer ultérieurement.
            </div>
        </div>

        <div class="bg-white p-3 shadow bg-opacity-50 rounded mw-100" v-if="!isClosed">
            <h2 class="title-section w-100 bg-primary rounded mb-3">Formulaire d'inscription</h2>

            <form method="POST" class="needs-validation" novalidate>
                <div class="row g-3">
                    <!-- Nom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">Nom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                        </div>
                    </div>

                    <!-- Prenom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">Prénom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="col-12 col-sm-8">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                required
                            />
                            <label for="email_address">Adresse email*</label>
                            <div class="invalid-feedback">Adresse email invalide</div>
                        </div>
                    </div>

                    <!-- Numero de telephone -->
                    <div class="col-12 col-sm-4">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="tel"
                                v-model="form_data.phone_number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0701020304"
                                :required="form_data.need_hotel"
                            />
                            <label for="phone_number">Numéro de téléphone</label>
                            <div class="invalid-feedback">Veuillez renseigner email invalide</div>
                        </div>
                    </div>

                    <!-- Fonctions -->
                    <div class="col-12">
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                v-model="form_data.company_role"
                                id="company_role"
                                name="company_role"
                                placeholder="Directeur commercial"
                                required
                            />
                            <label for="company_role">Fonction*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre fonction</div>
                        </div>
                    </div>

                    <!-- Structure -->
                    <div class="col-12">
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                v-model="form_data.company_name"
                                id="company_name"
                                name="company_name"
                                placeholder="SAS ENTREPRISE"
                                required
                            />
                            <label for="company_name">Structure*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre structure</div>
                        </div>
                    </div>

                    <!-- Type de structure -->
                    <div class="col-12">
                        <div class="form-floating">
                            <select
                                required
                                v-model="form_data.company_type"
                                class="form-select"
                                aria-label="Fonction"
                                id="company_type"
                                name="company_type"
                            >
                                <option value="Entreprise">Entreprise</option>
                                <option value="Laboratoire de recherche">Laboratoire de recherche</option>
                                <option value="Étudiant">Étudiant</option>
                                <option value="Association">Association</option>
                                <option value="Cluster/Institutionnel">Cluster/Institutionnel</option>
                                <option value="Autre">Autre</option>
                            </select>
                            <label for="company_type">Type de structure*</label>
                            <div class="invalid-feedback">Veuillez renseigner le type de votre structure</div>
                        </div>
                    </div>

                    <!-- Autre type de structure -->
                    <div class="col-12" v-if="form_data.company_type == 'Autre'">
                        <div class="form-floating">
                            <input
                                type="text"
                                class="form-control"
                                v-model="form_data.other_company_type"
                                id="other_company_type"
                                name="other_company_type"
                                placeholder=""
                                required
                            />
                            <label for="other_company_type">Précisez le type de votre structure*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre type de structure</div>
                        </div>
                    </div>

                    <!-- Choix de la table ronde -->
                    <div class="col-12 col-sm-auto">Choix de la table ronde :</div>
                    <div class="col-12">
                        <div class="form-floating">
                            <select
                                required
                                v-model="form_data.table_choix_1"
                                class="form-select"
                                aria-label="Choix n°1"
                                id="table_choix_1"
                                name="table_choix_1"
                            >
                                <option v-for="theme in theme_list" v-bind:key="theme.id" v-bind:value="theme.id">
                                    {{ theme.label }}
                                </option>
                            </select>
                            <label for="table_choix_1">Choix n°1*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre choix n°1</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-floating">
                            <select
                                :disabled="form_data.table_choix_1 == ''"
                                required
                                v-model="form_data.table_choix_2"
                                class="form-select"
                                aria-label="Choix n°2"
                                id="table_choix_2"
                                name="table_choix_2"
                            >
                                <option
                                    v-for="theme in theme_list.filter((e) => e.id != form_data.table_choix_1)"
                                    v-bind:value="theme.id"
                                    v-bind:key="theme.id"
                                >
                                    {{ theme.label }}
                                </option>
                            </select>
                            <label for="table_choix_2">Choix n°2*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre choix n°2</div>
                        </div>
                    </div>

                    <div class="col-12 m-0"></div>

                    <!-- Presence cocktail -->
                    <div class="col py-1 py-sm-3">Présence au cocktail convivial :</div>
                    <div class="col-auto d-flex align-items-center">
                        <button
                            type="button"
                            class="btn mx-3"
                            v-bind:class="[form_data.goto_cocktail ? 'btn-primary' : 'btn-outline-primary']"
                            @click="setGoToCocktail(true)"
                            for="chk_cocktail_yes"
                        >
                            Oui
                        </button>
                        <button
                            type="button"
                            class="btn"
                            v-bind:class="[form_data.goto_cocktail ? 'btn-outline-secondary' : 'btn-secondary']"
                            @click="setGoToCocktail(false)"
                            for="chk_cocktail_no"
                        >
                            Non
                        </button>
                    </div>
                </div>

                <button @click="register" class="w-100 btn btn-bicolor btn-lg mt-3" type="button">Valider</button>
                <div class="m-2 small">* champs obligatoires</div>
            </form>
        </div>

        <div class="bg-white text-center p-3 shadow bg-opacity-50 rounded" v-if="isClosed">
            <h4 class="mb-4 text-primary" style="line-height: 2rem">
                Nous sommes désolés, du fait de son succès, nous sommes dans l'obligation de clôturer les inscriptions à
                cet événement.
            </h4>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const saveMed5pRegistration = httpsCallable(functions, "saveMed5pRegistration");

const empty_form = {
    last_name: "",
    first_name: "",
    email: "",
    phone_number: "",
    company_role: "",
    company_name: "",
    company_type: "",
    other_company_type: "",
    table_choix_1: "",
    table_choix_2: "",
    goto_cocktail: false,
};

export default {
    created() {
        this.form_data = Object.assign({}, empty_form);
    },
    data() {
        return {
            isClosed: false,
            isLoading: false,
            hasError: false,
            theme_list: [
                { id: "theme1", label: "Thème 1 : [Prévention des cancers]" },
                {
                    id: "theme2",
                    label: "Thème 2 : [Santé mentale]",
                },
                {
                    id: "theme3",
                    label: "Thème 3 : [RSE]",
                },
            ],
            form_data: {
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0601020304",
                company_name: "DMP EVENTS",
                company_role: "Infirmier",
                company_type: "Étudiant",
                other_company_type: "",
                table: "t2",
                goto_cocktail: false,
            },
        };
    },
    props: {},
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        orate(is_orateur) {
            this.form_data.is_orateur = is_orateur;
            this.refresh_can_have_hotel();
        },
        select_type_structure(tnum) {
            this.form_data.table = tnum;
        },
        select_table(tnum) {
            this.form_data.table = tnum;
        },
        setGoToCocktail(goto_cocktail) {
            this.form_data.goto_cocktail = goto_cocktail;
        },

        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");
                if (form.checkValidity()) {
                    console.log(this.form_data);
                    await saveMed5pRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
.inscription {
    width: 100%;
    max-width: 768px;
}

$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
